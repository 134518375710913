var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
							name: 'projects-editor-uuid-index',
							params: {
								uuid: _vm.project.Uuid
							}
						}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),(_vm.configuration)?_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_c('span',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.configuration.Name)}}),_c('span',{staticClass:"text-small ml-2",domProps:{"textContent":_vm._s(`v. ${_vm.configuration.Version}`)}})]):_vm._e(),(_vm.localization_project)?_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" "+_vm._s(_vm.localization_project.Name)+" ")]):_vm._e()])]),(_vm.localization_project)?_c('div',{staticClass:"p-5 bg-light d-flex justify-content-center align-items-center"},[_c('div',[_c('h3',[_vm._v("Localization project "+_vm._s(_vm.localization_project.Name))]),_c('div',{staticClass:"d-flex"},[_c('router-link',{staticClass:"btn btn-outline-dark px-5 py-4 mx-2",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-index',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: _vm.localization_project.Id
										}
						}}},[_vm._v("Pay table")]),_c('router-link',{staticClass:"btn btn-outline-info px-5 py-4 mx-2",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-guide-index',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: _vm.localization_project.Id
										}
						}}},[_vm._v("Game guide")]),_c('router-link',{staticClass:"btn btn-outline-primary px-5 py-4 mx-2",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: _vm.localization_project.Id
										}
						}}},[_vm._v("Game content")])],1),_c('div',{staticClass:"d-flex justify-content-center"},[_c('router-link',{staticClass:"px-5 py-4 mx-2",attrs:{"to":{
									name: 'projects-editor-uuid-cuuid-localizations-project_id-download',
									params: {
										uuid: _vm.$route.params.uuid,
										cuuid: _vm.$route.params.cuuid,
										project_id: _vm.localization_project.Id
										}
						}}},[_vm._v("Download developer package")])],1)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }