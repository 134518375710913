<template>
	<div>
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<router-link :to="{name: 'projects-index'}">Projects</router-link>
				</li>

				<li class="breadcrumb-item" v-if="project">
					<router-link :to="{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: project.Uuid
								}
							}" v-text="`${project.Code} - ${project.Name}`"></router-link>
				</li>

				<li class="breadcrumb-item active" aria-current="page" v-if="configuration">
					<span class="font-weight-bold" v-text="configuration.Name"></span>
					<span class="text-small ml-2" v-text="`v. ${configuration.Version}`"></span>
				</li>

				<li class="breadcrumb-item active" v-if="localization_project">
					{{ localization_project.Name }}
				</li>

			</ol>
		</nav>

		<div class="p-5 bg-light d-flex justify-content-center align-items-center" v-if="localization_project">
			<div>
				<h3>Localization project {{ localization_project.Name }}</h3>
				<div class="d-flex">
					<router-link class="btn btn-outline-dark px-5 py-4 mx-2" :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-pay_table-index',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: localization_project.Id
											}
							}">Pay table</router-link>

					<router-link class="btn btn-outline-info px-5 py-4 mx-2" :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-guide-index',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: localization_project.Id
											}
							}">Game guide</router-link>

					<router-link class="btn btn-outline-primary px-5 py-4 mx-2" :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-content',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: localization_project.Id
											}
							}">Game content</router-link>
				</div>
				<div class="d-flex justify-content-center">
					<router-link class="px-5 py-4 mx-2" :to="{
										name: 'projects-editor-uuid-cuuid-localizations-project_id-download',
										params: {
											uuid: $route.params.uuid,
											cuuid: $route.params.cuuid,
											project_id: localization_project.Id
											}
							}">Download developer package</router-link>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import api from '@/api'

export default {
	name: "index",
	data: () => ({
		loading: false,
		project: null,
		configuration: null,
		localization_project: null,
	}),
	async mounted() {
		this.loading = true
		this.project = await api.Projects.getProject(this.$route.params.uuid)
		this.configuration = await api.Projects.getProjectConfig(this.$route.params.cuuid)
		this.localization_project = await api.Localizations.LocalizationProjects.get(this.$route.params.project_id)
		this.loading = false
	}
}
</script>

<style scoped>

</style>
